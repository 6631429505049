import React from 'react';
import Map from '../images/map.png';

class Beach extends React.Component {
    render(){
        return (
            <div className="mainContent">
                <div className="textContent leftAlign">
                    <h2>About the Beach</h2>
                    Highcliffe Beach is the stretch of beach between Highcliffe Castle and The Cliffhanger Cafe. It is a large, stony beach backed by cliffs.
                    <br/><br/>
                    <h3>Location</h3>
                    <p><span className="strong">Address:</span> Wharncliffe Road, Highcliffe, BH23 5DE</p>
                    <p>We meet at Groyne H5 which is in the middle of the area we cover and is on the beach - we do not meet in the car park.</p>
                    <p><span className="strong">What 3 Words:</span> hairpin.diplomas.ants</p>
                    <p><span className="strong">Car park information:</span> <a href="https://www.bcpcouncil.gov.uk/Parking/Find-a-car-park/car-park-single.aspx?carpark=Highcliffe%20Cliff%20Top%20car%20park" target="_blank" rel="noopener noreferrer">Highcliffe Cliff Top car park</a></p>
                    <p>You can find out more about travel and location at the <a href="https://www.visit-dorset.com/things-to-do/highcliffe-beach-p1141233" target="_blank" rel="noopener noreferrer">Visit Dorset website</a></p>
                    <p>This image shows the location of the beach and our meeting point - Groyne H5. This groyne number is the half way point of the area that we cover so we are easily located and can keep eyes on attendees throughout the event.</p>                    
                    <p className="centreAlign">
                        <img src={Map} alt="Static map showing the meeting location" />
                        <br/><a href={Map} download>Download the map</a>
                    </p>
                    <p className="centreAlign"></p>
                </div>
                <div className="textContent leftAlign">
                    <h2>Other Information</h2>
                    There are some dog restrictions on this beach.
                    <br/><br/>
                    Dogs are allowed all year round from Groyne H11 to Groyne H8.
                    <br/><br/>
                    Beyond Groyne H8, from May to September dogs are not allowed on the beach, this usually only affects one of our events in the summer. For the rest of the year you can bring dogs but please do try and keep them away from the kit – we have had dogs using the kit bags as toilets and that’s not pleasant for anyone!
                    <br/><br/>
                    The event is restricted to the beach, but you are permitted to walk along the designated pathways to litter pick if you choose to do so. Please do not scramble around on the rock groynes or attempt to walk on the grassy /slope areas where there are not proper footpaths.
                    <br/><br/>
                </div>
            </div>  
        )
    }
}

export default Beach;
