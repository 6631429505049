import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import './styles/styles.css';

import Header from './constants/header.js';
import Footer from './constants/footer.js';
import Beach from './pages/beach';
import Contact from './pages/contact';
import Dates from './pages/dates';
import Home from './pages/home';
import Info from './pages/information';
import Stats from './pages/stats';
import Team from './pages/team';
import Privacy from './pages/privacy';
import Cookies from './pages/cookies';

function App() {

const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/team") {
      document.querySelector('[id^="kofi-widget-overlay-"]').style.display = "block";
    } else {
      document.querySelector('[id^="kofi-widget-overlay-"]').style.display = "none";
    }
  }, [location]);

  return (
    <div>
      <Header />
      <main>
        <Routes>
          <Route path = "/" exact element={<Home />} />
          <Route path = "/team" element={<Team/>} />
          <Route path = "/information" element={<Info/>} />
          <Route path = "/beach" element={<Beach/>} />
          <Route path = "/dates" element={<Dates/>} />
          <Route path = "/contact" element={<Contact/>} />
          <Route path = "/stats" element={<Stats/>} />
          <Route path = "/privacy" element={<Privacy/>} />
          <Route path = "/cookies" element={<Cookies/>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
