import React from 'react';

class Stats extends React.Component {
    render(){
        return (
            <div className="mainContent">
                <div className="textContent justify">
                    <h2>Team Statistics</h2>
                    As you return from your beach clean, we collect up all the bags and (if we’ve remembered the scales!) we weigh them and let everyone know how many bags and the weight of the litter collected for each event.
                    <br/><br/>
                    We don’t have any statistics for 2021 as unfortunately most of the events had to be cancelled either due to Covid or due to adverse weather. We did go ahead in October, but there is no recorded data.
                    <br/><br/>
                </div>
                <div className="textContent">
                    <table className="tblStats">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>No. Volunteers</th>
                                <th>Qty</th>
                                <th>Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td>14/07/2024</td>
                                <td>24</td>
                                <td>3</td>
                                <td>11.9kg</td>
                            </tr>
                         <tr>
                                <td>21/04/2024</td>
                                <td>18</td>
                                <td>3</td>
                                <td>8.65kg</td>
                            </tr>
                            <tr>
                                <td>07/01/2024</td>
                                <td>23</td>
                                <td>Not counted</td>
                                <td>12.36kg</td>
                            </tr>
                            <tr>
                                <td>15/10/2023</td>
                                <td>22</td>
                                <td>Not counted</td>
                                <td>15.76kg</td>
                            </tr>
                            <tr>
                                <td>23/07/2023</td>
                                <td>36</td>
                                <td>Not counted</td>
                                <td>26.63kg</td>
                            </tr>
                            <tr>
                                <td>15/01/2023</td>
                                <td>32</td>
                                <td>18 bags</td>
                                <td>19.57kg</td>
                            </tr>
                            <tr>
                                <td>16/10/2022</td>
                                <td>19</td>
                                <td>14 bags</td>
                                <td>22.6kg</td>
                            </tr>
                            <tr>
                                <td>07/08/2022</td>
                                <td>16</td>
                                <td>13 bags</td>
                                <td>11.65kg</td>
                            </tr>
                            <tr>
                                <td>20/03/2022</td>
                                <td>20</td>
                                <td>10 bags</td>
                                <td>9.59kg</td>
                            </tr>
                            <tr>
                                <td>09/01/2022</td>
                                <td>34</td>
                                <td>23 bags</td>
                                <td>20.61kg</td>
                            </tr>
                            <tr>
                                <td>19/01/2020</td>
                                <td></td>
                                <td>40 bags</td>
                                <td>41.69kg</td>
                            </tr>
                            <tr>
                                <td>06/10/2019</td>
                                <td></td>
                                <td>16 Bags</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>14/07/2019</td>
                                <td></td>
                                <td>25 Bags</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>28/04/2019</td>
                                <td></td>
                                <td>38 Bags</td>
                                <td>53.16kg</td>
                            </tr>
                            <tr>
                                <td>20/01/2019</td>
                                <td></td>
                                <td></td>
                                <td>70.42kg</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    
    }
}

export default Stats;
