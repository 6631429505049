import React from 'react';

class Dates extends React.Component {
    render(){
        return (
            <div className="mainContent">
                <div className="textContent">
                    <h2>Event Dates</h2>
                    All dates are subject to change or cancellation - adverse weather may force last minute changes!
                    <br/><br/>
                    Please check before coming. Updates will be sent via email to the mailing list and posted on social media.
                    <br/><br/>
                </div>
                <br/>
                <div className="textContent desktopOnly">
                    <table>
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Event Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="past">
                                <td>Sunday</td>
                                <td>7th January 2024</td>
                                <td>10.30am - 12.30pm</td>
                                <td><a className="clearLink" href="https://fb.me/e/1UiyadRxZ" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></td>
                            </tr>
                            <tr className="past">
                                <td>Sunday</td>
                                <td>21st April 2024</td>
                                <td>10.30am - 12.30pm</td>
                                <td><a className="clearLink" href="https://fb.me/e/1mbbCMqr0" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></td>
                            </tr>
                            <tr className="past">
                                <td>Sunday</td>
                                <td>14th July 2024</td>
                                <td>10.30am - 12.30pm</td>
                                <td><a className="clearLink" href="https://fb.me/e/MIE58vjU" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></td>
                            </tr>
                            <tr className="past">
                                <td>Sunday</td>
                                <td>20th October 2024 - CANCELLED</td>
                                <td>10.30am - 12.30pm</td>
                                <td><a className="clearLink" href="https://fb.me/e/1alf3iQbr" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></td>
                            </tr>
                            <tr className="next">
                                <td>Sunday</td>
                                <td>12th January 2025</td>
                                <td>10.30am - 12.30pm</td>
                                <td><a className="clearLink" href="https://fb.me/e/2UxKBpQ0a" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className = "mobileOnly">
                    <p className="dateLine past">Sunday 7th January 2024<br/>10-30am - 12.30pm<br/><a className="clearLink" href="https://fb.me/e/1UiyadRxZ" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></p>
                    <p className="dateLine past">Sunday 21st April 2024<br/>10-30am - 12.30pm<br/><a className="clearLink" href="https://fb.me/e/1mbbCMqr0" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></p>
                    <p className="dateLine past">Sunday 14th July 2024<br/>10-30am - 12.30pm<br/><a className="clearLink" href="https://fb.me/e/MIE58vjU" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></p>
                    <p className="dateLine past">Sunday 20th October 2024 - CANCELLED<br/>10-30am - 12.30pm<br/><a className="clearLink" href="https://fb.me/e/1alf3iQbr" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></p>
                    <p className="dateLine next">Sunday 12th January 2025<br/>10-30am - 12.30pm<br/><a className="clearLink" href="https://fb.me/e/2UxKBpQ0a" target="_blank" rel="noopener, noreferrer">Facebook Event Page</a></p>
                </div>
            </div>
        )
    
    }
}

export default Dates;
