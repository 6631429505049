import React from 'react';
import Banner from '../constants/banner';
import Brownies1 from '../images/Brownies2.jpg';
import Brownies1320 from '../images/Brownies2-320.jpg';
import Brownies1568 from '../images/Brownies2-568.jpg';
import Brownies1768 from '../images/Brownies2-768.jpg';
import Brownies11024 from '../images/Brownies2-1024.jpg';
import Brownies11280 from '../images/Brownies2-1280.jpg';
import Brownies11366 from '../images/Brownies2-1366.jpg';
import Brownies11680 from '../images/Brownies2-1680.jpg';
import Brownies11920 from '../images/Brownies2-1920.jpg';
import Brownies2 from '../images/Brownies5.jpg';
import Brownies2320 from '../images/Brownies5-320.jpg';
import Brownies2568 from '../images/Brownies5-568.jpg';
import Brownies2768 from '../images/Brownies5-768.jpg';
import Brownies21024 from '../images/Brownies5-1024.jpg';
import Brownies21280 from '../images/Brownies5-1280.jpg';
import Brownies21366 from '../images/Brownies5-1366.jpg';
import Brownies21680 from '../images/Brownies5-1680.jpg';
import Brownies21920 from '../images/Brownies5-1920.jpg';

class Home extends React.Component {
    render(){
        return (
            <div className = "homeContent">
                <section className='banner' title="Banner image">
                    <div className="bannerOverlay"></div>
                    <Banner />
                </section>
                <section title="Main page content">
                    <div className="textContent">
                        <h2>Welcome to Highcliffe Beach Clean Team</h2>
                        Meeting quarterly on Highcliffe Beach for two hours on a Sunday morning, the team help to keep litter under control on this beautiful stretch of coast.
                        <br/><br/>Anyone is welcome to join us, you can just turn up at any event. 
                        <br/><br/>You can sign up to our mailing list through our <a href="/contact" className="clearLink">contact form</a> for event notifications or follow us on social media.
                    </div>
                    <div className="textContent">
                        <h3>3rd Highcliffe Brownies have a message for you</h3>
                        Our 3rd Highcliffe Brownies have been Speaking Out for our beaches and oceans and would like everyone to help protect them. Have a look at these brilliant posters that were sent to us by Leah and Megan and remember … take your litter home.
                        <div className="imageBlock">
                            <div className="colhalf">
                                <img 
                                    src={Brownies1} alt="Artwork from the Brownies showing ocean life and asking us to stop polluting the ocean." width="1654px" height="2338px"
                                    srcSet={`
                                        ${Brownies1320} 320w,    
                                        ${Brownies1568} 568w,
                                        ${Brownies1768} 768w,
                                        ${Brownies11024} 1024w,
                                        ${Brownies11280} 1280w,
                                        ${Brownies11366} 1366w,
                                        ${Brownies11680} 1680w,
                                        ${Brownies11920} 1920w
                                    `}
                                    sizes="40vw"
                                />
                            </div>
                            <div className="colhalf">
                                <img 
                                    src={Brownies2} alt="Artwork from the Brownies showing ocean life and asking for help to stop global warming." width="1654px" height="2338px"
                                    srcSet={`
                                        ${Brownies2320} 320w,
                                        ${Brownies2568} 568w,
                                        ${Brownies2768} 768w,
                                        ${Brownies21024} 1024w,
                                        ${Brownies21280} 1280w,
                                        ${Brownies21366} 1366w,
                                        ${Brownies21680} 1680w,
                                        ${Brownies21920} 1920w
                                    `}
                                    sizes="40vw"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Home;
